import React from "react"
import { Typography, useTheme, Box } from "@material-ui/core"
import TextList from "../TextList"
import { PropTypes } from "prop-types"

const TextFormatter = ({ content, keyPrefix, ...rest }) => {
  const theme = useTheme()
  return (
    <Box {...rest}>
      {content &&
        content.map((item, i) => {
          const key = `content-item-${keyPrefix}-${i}`
          if (item.type === "h1") {
            return (
              <Typography
                key={key}
                variant="h1"
                style={{
                  marginTop: i !== 0 && theme.spacing(6),
                  marginBottom: theme.spacing(4),
                }}
              >
                {item.data}
              </Typography>
            )
          }
          if (item.type === "h2") {
            return (
              <Typography
                key={key}
                variant="h2"
                style={{ marginTop: i !== 0 && theme.spacing(6) }}
              >
                {item.data}
              </Typography>
            )
          }
          if (item.type === "h3") {
            return (
              <Typography
                key={key}
                variant="h3"
                style={{ marginTop: i !== 0 && theme.spacing(3) }}
              >
                {item.data}
              </Typography>
            )
          }
          if (item.type === "h4") {
            return (
              <Typography
                key={key}
                variant="h4"
                style={{ marginTop: i !== 0 && theme.spacing(3) }}
              >
                {item.data}
              </Typography>
            )
          }
          if (item.type === "paragraph") {
            return (
              <Typography key={key} variant="body1">
                {item.data}
              </Typography>
            )
          }
          if (item.type === "list") {
            return <TextList key={key} items={item.data} listIcon="—" />
          }
        })}
    </Box>
  )
}

export const Content = {
  type: PropTypes.string,
  data: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

TextFormatter.propTypes = {
  content: PropTypes.arrayOf(Content),
  keyPrefix: PropTypes.string,
}

export default TextFormatter
