import React from "react"
import { Typography, Box, useTheme } from "@material-ui/core"
import PropTypes from "prop-types"

import SimpleIconButton from "../../SimpleIconButton"
import { X } from "react-feather"

import { createStyles, makeStyles } from "@material-ui/core/styles"
import TextFormatter from "../../TextFormatter/TextFormatter"

const useStyles = makeStyles(theme => {
  return createStyles({
    container: {
      padding: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
      },
    },
  })
})

const SecurityFeatureModalContentBox = ({
  title,
  content,
  iconName,
  onClose,
  imageFluid,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Box {...rest} className={classes.container}>
      <Box display="flex" justifyContent="flex-end">
        {onClose && (
          <SimpleIconButton
            onClick={onClose}
            color="black"
            hoverColor={theme.palette.primary.main}
          >
            <X size={32} />
          </SimpleIconButton>
        )}
      </Box>
      <Box width="100px" minWidth="100px" mb={4}>
        {imageFluid}
      </Box>
      {title && (
        <Box mb={2}>
          <Typography variant="h3">{title}</Typography>
        </Box>
      )}
      <Box maxWidth="650px">
        <TextFormatter content={content} keyPrefix="security-modal" />
      </Box>
    </Box>
  )
}

SecurityFeatureModalContentBox.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  onClose: PropTypes.func,
  content: PropTypes.shape({
    paragraphs: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default SecurityFeatureModalContentBox
