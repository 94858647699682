import React from "react"
import { Typography, Paper } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => {
  return createStyles({
    paperRoot: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(6),
      },
    },
  })
})

const PaperContentBox = ({ children }) => {
  const classes = useStyles()
  return (
    <Paper classes={{ root: classes.paperRoot }} elevation={0}>
      {children}
    </Paper>
  )
}

PaperContentBox.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
}

export default PaperContentBox
