import React from "react"
import { Box, Typography, Grid } from "@material-ui/core"
import PropTypes from "prop-types"
import { ArticleContentBox } from "../../ContentBoxes"

const ArticlesSection = ({ articles }) => {
  return (
    <Grid container spacing={4}>
      {articles &&
        articles.map((a, i) => (
          <Grid item xs={12} sm={6}>
            <ArticleContentBox
              key={`article-${i}`}
              date={a.date}
              imageName={a.imageName}
              preview={a.preview}
              link={a.link}
              logo={a.logo}
              logoHeight={a.logoHeight}
            />
          </Grid>
        ))}
    </Grid>
  )
}

const Article = PropTypes.shape({
  date: PropTypes.string,
  preview: PropTypes.string,
  link: PropTypes.string,
})

ArticlesSection.propTypes = {
  articles: PropTypes.arrayOf(Article),
}

export default ArticlesSection
