import React from "react"

import { Button } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    button: {
      padding: 0,
      backgroundColor: "transparent",
      "& span": {
        transition: ".25s",
        fontWeight: 500,
        color: props => props.color,
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& span": {
          color: props => props.hoverColor,
        },
      },
    },
  })
})

const SimpleButton = ({ children, color, hoverColor, ...rest }) => {
  const classes = useStyles({ color, hoverColor })
  return (
    <Button classes={{ root: classes.button }} {...rest}>
      {children}
    </Button>
  )
}

export default SimpleButton
