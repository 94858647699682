import React from "react"
import { Typography, Box, Button } from "@material-ui/core"
import PropTypes from "prop-types"
import { Paperclip } from "../../Icons"
import { LoadedImage } from "../../images"
import SimpleButton from "../../SimpleButton"

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    container: {
      maxWidth: "380px",
      marginBottom: theme.spacing(1),
    },
    iconContainer: {
      height: "40px",
      marginBottom: theme.spacing(2),
      display: "flex",
      alignItems: "flex-end",
      "& > div": {
        height: "100%",
      },
      "& img": {
        objectPosition: "left center !important",
      },
    },
    date: {
      paddingBottom: theme.spacing(1),
      display: "block",
    },
    link: {
      fontWeight: 500,
      "& span": {
        color: theme.palette.primary.main,
      },
    },
  })
})

const ArticleContentBox = ({
  imageName,
  date,
  preview,
  link,
  logo,
  logoHeight,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Box {...rest} classes={{ root: classes.container }}>
      {logo && (
        <Box classes={{ root: classes.iconContainer }}>
          <img height={`${logoHeight || 100}%`} src={logo} />
        </Box>
      )}
      {date && (
        <Typography classes={{ root: classes.date }} variant="caption">
          {date}
        </Typography>
      )}
      {preview && <Typography variant="body1">{preview}</Typography>}
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <SimpleButton
            className={classes.link}
            size="small"
            color={theme.palette.primary.main}
            hoverColor={theme.palette.primary.dark}
            startIcon={<Paperclip />}
          >
            Read more
          </SimpleButton>
        </a>
      )}
    </Box>
  )
}

ArticleContentBox.propTypes = {
  imageName: PropTypes.string,
  date: PropTypes.string,
  preview: PropTypes.string,
  link: PropTypes.string,
}

export default ArticleContentBox
