import React from "react"
import { Box, Typography } from "@material-ui/core"
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { BasicContentBox } from "../../ContentBoxes"
import { BoxesSection } from "../"

const useStyles = makeStyles(theme => {
  return createStyles({
    h1: {
      maxWidth: "550px",
      padding: `0 ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
      [theme.breakpoints.up("md")]: {
        padding: 0,
      },
    },
    paperBoxContainer: {
      position: "relative",
      marginTop: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        marginTop: 0,
        top: `-${theme.spacing(2)}px`,
      },
    },
  })
})

const FirstSection = ({ h1, boxHeader, cta, children, image }) => {
  const classes = useStyles()

  return (
    <Box mb={16}>
      {h1 && (
        <Typography classes={{ root: classes.h1 }} variant="h1">
          {h1}
        </Typography>
      )}
      <Box classes={{ root: classes.paperBoxContainer }}>
        <BoxesSection
          boxes={[
            {
              align: "right",
              maxWidth: "500px",
              margin: { xs: { bottom: "150px" } },
              content: (
                <BasicContentBox header={boxHeader}>
                  <>
                    {children}
                    {cta && <Box mt={4}>{cta}</Box>}
                  </>
                </BasicContentBox>
              ),
            },
          ]}
          image={{
            name: image,
            position: { md: { top: "70px" } },
          }}
        />
      </Box>
    </Box>
  )
}

FirstSection.propTypes = {
  h1: PropTypes.string,
  boxHeader: PropTypes.string,
}

export default FirstSection
