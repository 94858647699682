import React from "react"
import { Image } from "./Images"
import { useStaticQuery, graphql } from "gatsby"

export const Auvents2 = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "auvents-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return <Image data={data} />
}

export default Auvents2
