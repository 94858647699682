import React from "react"
import { Typography, Box, Grid } from "@material-ui/core"
import PropTypes from "prop-types"

const ThreeColumnsContentBox = ({ boxes, ...rest }) => {
  return (
    <Grid container spacing={4} {...rest}>
      {boxes &&
        boxes.map(b => (
          <Grid item xs={12} sm={6} md={4}>
            {b.icon && <Box mb={2}>{b.icon}</Box>}
            <Typography variant="h3">{b.header}</Typography>
            {b.paragraphs &&
              b.paragraphs.map(p => (
                <Typography variant="body1">{p}</Typography>
              ))}
            <Box mt={4}>{b.cta}</Box>
          </Grid>
        ))}
      <Grid item xs={0} sm={6}></Grid>
    </Grid>
  )
}

ThreeColumnsContentBox.propTypes = {
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      paragraphs: PropTypes.arrayOf(PropTypes.string),
      cta: PropTypes.node,
      icon: PropTypes.node,
    })
  ),
}

export default ThreeColumnsContentBox
