import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const Image = ({ data }) => {
  return (
    <Img
      style={{ width: "100%", height: "100%", position: "absolute" }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}
