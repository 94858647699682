import React from "react"
import { Image } from "./Images"
import { useStaticQuery, graphql } from "gatsby"

export const Perspective2 = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "perspective-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 990) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return <Image data={data} />
}

export default Perspective2
