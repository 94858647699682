import React from "react"

import { IconButton } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    button: {
      padding: 0,
      backgroundColor: "transparent",
      "& svg": {
        transition: ".25s",
        stroke: props => props.color,
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& svg": {
          stroke: props => props.hoverColor,
        },
      },
    },
  })
})

const SimpleIconButton = ({ children, color, hoverColor, ...rest }) => {
  const classes = useStyles({ color, hoverColor })
  return (
    <IconButton classes={{ root: classes.button }} {...rest}>
      {children}
    </IconButton>
  )
}

export default SimpleIconButton
