import React from "react"

import { Typography, Box, Button } from "@material-ui/core"
import PropTypes from "prop-types"
import Icons from "../../Icons"
import SimpleButton from "../../SimpleButton"

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    container: {
      maxWidth: "380px",
      marginBottom: theme.spacing(1),
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      "& span": {
        transition: "0.25s",
      },
      "&:hover": {
        cursor: "pointer",
        "& span": {
          color: theme.palette.primary.dark,
        },
      },
    },
  })
})

const SecurityFeatureContentBox = ({
  text,
  title,
  iconName,
  handleClick,
  imageFluid,
  ...rest
}) => {
  const classes = useStyles()
  const Icon = iconName ? Icons[iconName] : null
  const theme = useTheme()
  return (
    <Box {...rest} classes={{ root: classes.container }} onClick={handleClick}>
      <Box width="64px" minWidth="64px" mr={2} mb={2}>
        {imageFluid}
      </Box>
      <Box>
        {title && <Typography variant="h3">{title}</Typography>}
        {text && <Typography variant="body1">{text}</Typography>}
        <SimpleButton
          size="small"
          color={theme.palette.primary.main}
          hoverColor={theme.palette.primary.dark}
        >
          Read more
        </SimpleButton>
      </Box>
    </Box>
  )
}

SecurityFeatureContentBox.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  iconName: PropTypes.string,
  handleClick: PropTypes.func,
}

export default SecurityFeatureContentBox
