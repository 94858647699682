import React from "react"
import { Typography, Box } from "@material-ui/core"
import PropTypes from "prop-types"

const BasicContentBox = ({ header, children, ...rest }) => {
  return (
    <Box {...rest}>
      {header && <Typography variant="h2">{header}</Typography>}
      {children}
    </Box>
  )
}

BasicContentBox.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
}

export default BasicContentBox
