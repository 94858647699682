import Auvents from "./Auvents"
import Auvents1 from "./Auvents1"
import Auvents2 from "./Auvents2"
import Cieling from "./Ceiling"
import Cieling1 from "./Cieling1"
import Cieling2 from "./Cieling2"
import Boulder from "./Boulder"
import Boulder1 from "./Boulder1"
import Boulder2 from "./Boulder2"
import BitcoinBg from "./BitcoinBg"
import Stairs from "./Stairs"
import Stairs1 from "./Stairs1"
import Stairs2 from "./Stairs2"
import Perspective from "./Perspective"
import Perspective1 from "./Perspective1"
import Perspective2 from "./Perspective2"
import Perspective3 from "./Perspective3"
import Hardware from "./Hardware"
import TheBlockLogo from "./TheBlockLogo"
import BitcoinMagazineLogo from "./BitcoinMagazineLogo"

export { Hardware, TheBlockLogo, BitcoinMagazineLogo }

export default {
  Auvents,
  Auvents1,
  Auvents2,
  Cieling,
  Cieling1,
  Cieling2,
  Boulder,
  Boulder1,
  Boulder2,
  BitcoinBg,
  Stairs,
  Stairs1,
  Stairs2,
  Perspective,
  Perspective1,
  Perspective2,
  Perspective3,
}
