import React from "react"
import { Typography, Box } from "@material-ui/core"
import PropTypes from "prop-types"

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"
import { LinkedIn, Twitter, GitHub } from "../../Icons"

import SocialIconWrapper from "../../SocialIconWrapper"

const useStyles = makeStyles(theme => {
  return createStyles({
    text: {
      marginBottom: 0,
    },
    li: {
      marginBottom: theme.spacing(1),
    },
  })
})

const EmployeesListContentBox = ({ employees, ...rest }) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <ul {...rest}>
      {employees &&
        employees.map((e, i) => (
          <li className={classes.li}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              paddingY={0.2}
              style={{
                borderBottom: `1px solid rgba(0,0,0,0.07)`,
              }}
            >
              <Typography
                classes={{ root: classes.text }}
              >{`${e.name}, ${e.title}`}</Typography>
              <Box
                marginRight={`-${theme.spacing(2)}`}
                marginLeft={3}
                display="flex"
                alignItems="flex-end"
              >
                {e.linkedIn && (
                  <Box mr={1}>
                    <SocialIconWrapper
                      url={e.linkedIn}
                      hoverColor={theme.palette.primary.light}
                    >
                      <LinkedIn fill={theme.palette.grey[100]} width="22px" />
                    </SocialIconWrapper>
                  </Box>
                )}
                {e.twitter && (
                  <Box mr={1}>
                    <SocialIconWrapper
                      url={e.twitter}
                      hoverColor={theme.palette.primary.light}
                    >
                      <Twitter
                        fill={theme.palette.grey[100]}
                        width="22px"
                        marginX={2}
                      />
                    </SocialIconWrapper>
                  </Box>
                )}
                {e.gitHub && (
                  <Box mr={1}>
                    <SocialIconWrapper
                      url={e.gitHub}
                      hoverColor={theme.palette.primary.light}
                    >
                      <GitHub
                        fill={theme.palette.grey[100]}
                        width="24px"
                        marginX={2}
                      />
                    </SocialIconWrapper>
                  </Box>
                )}
              </Box>
            </Box>
          </li>
        ))}
    </ul>
  )
}

const Employee = PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  linkedIn: PropTypes.string,
  twitter: PropTypes.string,
})

EmployeesListContentBox.propTypes = {
  employees: PropTypes.arrayOf(Employee),
}

export default EmployeesListContentBox
