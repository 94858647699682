import React from "react"
import { Box, useMediaQuery } from "@material-ui/core"
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { PaperContentBox } from "../../ContentBoxes"

import Images from "../../images"

const useStyles = makeStyles(theme => {
  return createStyles({
    imageWrapper: props => {
      const imagePosition = props.image ? props.image.position || {} : {}
      const xs = imagePosition.xs || {}
      const sm = imagePosition.sm || {}
      const md = imagePosition.md || {}

      return {
        overflow: "hidden",
        position: "absolute",
        top: xs.top || 0,
        bottom: xs.bottom || 0,
        left: xs.left || 0,
        right: xs.right || 0,
        [theme.breakpoints.up("sm")]: {
          top: sm.top || xs.top || 0,
          bottom: sm.bottom || xs.bottom || 0,
          left: sm.left || xs.left || 0,
          right: sm.right || xs.right || 0,
        },
        [theme.breakpoints.up("md")]: {
          top: md.top || sm.top || xs.top || 0,
          bottom: md.bottom || sm.bottom || xs.bottom || 0,
          left: md.left || sm.left || xs.left || 0,
          right: md.right || sm.right || xs.right || 0,
        },
        maxHeight: props.image ? props.image.maxHeight || null : null,
        "& .gatsby-image-wrapper": {
          height: "100%",
        },
      }
    },
  })
})

const BoxesSection = ({ boxes, ...rest }) => {
  const classes = useStyles(rest)
  const theme = useTheme()

  const Image = rest.image && rest.image.name ? Images[rest.image.name] : null
  const isMd = useMediaQuery(theme.breakpoints.up("md"))
  const isSm = useMediaQuery(theme.breakpoints.up("sm"))

  const getBoxMargin = b => {
    if (b.margin) {
      let correctKey
      let correctKey2
      let correctKey3
      if (isMd) {
        correctKey = "md"
        correctKey2 = "sm"
        correctKey3 = "xs"
      } else if (isSm) {
        correctKey = "sm"
        correctKey2 = "xs"
      } else {
        correctKey = "xs"
      }

      return (
        b.margin[correctKey] ||
        b.margin[correctKey2] ||
        b.margin[correctKey3] ||
        {}
      )
    }
    return {}
  }

  return (
    <Box position="relative" mb={20}>
      <Box classes={{ root: classes.imageWrapper }}>{!!Image && <Image />}</Box>
      {boxes &&
        boxes.length > 0 &&
        boxes.map((b, i) => {
          const { content, align, maxWidth } = b
          const boxMargin = getBoxMargin(b)
          const isRightAligned = align && align === "right"
          const alignmentPadding = isSm
            ? `${theme.spacing(6)}px`
            : `${theme.spacing(4)}px`
          console.log("alignement padding", alignmentPadding)
          return (
            <Box
              key={`box-${i}`}
              display="flex"
              paddingTop={boxMargin.top || 0}
              paddingBottom={boxMargin.bottom || 0}
              paddingLeft={
                isRightAligned ? alignmentPadding : boxMargin.left || 0
              }
              paddingRight={
                isRightAligned ? boxMargin.right || 0 : alignmentPadding
              }
              justifyContent={isRightAligned ? "flex-end" : "flex-start"}
            >
              <Box position="relative" maxWidth={maxWidth}>
                <PaperContentBox>{content}</PaperContentBox>
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

const Positions = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
}

const PaperBox = PropTypes.shape({
  content: PropTypes.node,
  margin: PropTypes.shape({
    xs: Positions,
    sm: Positions,
    md: Positions,
  }),
  align: PropTypes.oneOf(["left", "right"]),
  maxWidth: PropTypes.string,
})

BoxesSection.propTypes = {
  boxes: PropTypes.arrayOf(PaperBox),
  image: PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.shape({
      xs: Positions,
      sm: Positions,
      md: Positions,
    }),
  }),
}

export default BoxesSection
