import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Modal, Box, Dialog } from "@material-ui/core"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import {
  SecurityFeatureContentBox,
  SecurityFeatureModalContentBox,
} from "../../ContentBoxes"

const SecurityFeaturesSection = ({ features }) => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const handleClose = () => {
    setSelectedIndex(null)
  }
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativeDirectory: { eq: "featureIcons" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
            base
          }
        }
      }
    }
  `)

  const modalContent = selectedIndex ? features[selectedIndex - 1] : {}

  const images = data.allFile.edges.reduce((acc, el) => {
    if (el.node && el.node.base) {
      const fileName = el.node.base.split(".")[0]
      acc[fileName] = el.node.childImageSharp.fluid
    }
    return acc
  }, {})

  console.log("images", images)

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={!!selectedIndex}
        maxWidth="md"
        PaperProps={{ elevation: 0 }}
      >
        {selectedIndex && (
          <SecurityFeatureModalContentBox
            onClose={handleClose}
            content={modalContent.content}
            title={modalContent.title}
            iconName={modalContent.iconName}
            imageFluid={
              !!images[modalContent.iconName] ? (
                <Img fluid={images[modalContent.iconName]} />
              ) : null
            }
          />
        )}
      </Dialog>
      <Grid container spacing={4}>
        {features &&
          features.map((a, i) => (
            <Grid item xs={12} sm={6} key={`sec-feature-${i}`}>
              <SecurityFeatureContentBox
                key={`article-${i}`}
                title={a.title}
                text={a.preview}
                iconName={a.iconName}
                imageFluid={
                  !!images[a.iconName] ? (
                    <Img fluid={images[a.iconName]} />
                  ) : null
                }
                handleClick={() => setSelectedIndex(i + 1)}
              />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

const Feature = PropTypes.shape({
  title: PropTypes.string,
  preview: PropTypes.string,
  iconName: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, data: PropTypes.any })
  ),
})

SecurityFeaturesSection.propTypes = {
  features: PropTypes.arrayOf(Feature),
}

export default SecurityFeaturesSection
