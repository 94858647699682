import React from "react"
import { Box, Typography } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => {
  return createStyles({
    sectionContainer: {
      display: "flex",
      justifyContent: "center",
      padding: `0 ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
      [theme.breakpoints.up("smd")]: {
        padding: 0,
      },
      margin: `${theme.spacing(20)}px 0`,
    },
    section: {
      maxWidth: "600px",
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: "3rem",
      lineHeight: "3.56rem",
      fontWeight: 500,
    },
    paragraph: {
      fontSize: "1.5rem",
      lineHeight: "2.3rem",
      fontWeight: 500,
    },
  })
})

const FocusSection = ({ title, paragraphs, cta, align, children }) => {
  const classes = useStyles()

  return (
    <Box classes={{ root: classes.sectionContainer }}>
      <Box classes={{ root: classes.section }}>
        {title && (
          <Typography
            classes={{ root: classes.title }}
            variant="h2"
            align={align}
          >
            {title}
          </Typography>
        )}
        {paragraphs &&
          paragraphs.length > 0 &&
          paragraphs.map((p, i) => (
            <Typography
              key={`focus-${i}`}
              classes={{ root: classes.paragraph }}
              variant="body1"
              align={align}
            >
              {p}
            </Typography>
          ))}
        {children}
        {cta && (
          <Box mt={6} display="flex" justifyContent={align}>
            {cta}
          </Box>
        )}
      </Box>
    </Box>
  )
}

FocusSection.propTypes = {
  title: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.string),
  cta: PropTypes.node,
  align: PropTypes.oneOf(["center"]),
}

export default FocusSection
